import React, { useEffect, useMemo, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import { TextField } from '@material-ui/core'
import { allSongAction } from '../../services/actions/song.action'
import { shiftUpdateSongElement } from '../../utils/commonFunctions'
import { putMethod } from '../../helper/ApiMethods'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { useStyles } from '../../utils/styled'
import { Api } from '../../helper/ApiList'
import { LeftRightPaneFolderTypes } from '../../utils/commonOptions'
import { toast } from '../Toast/Toast'

const EditorSongHeading = () => {
  const classes = useStyles()
  const { singleSong, singleNotebook } = useSelector(state => state.singleSong)
  const [songHeading, setSongHeading] = useState('')
  const { token } = useSelector(state => state.auth)
  const { type } = useSelector(state => state.songs)

  const { songs, title, folderId } = useSelector(state => state.songs)

  const alert = useAlert()
  const [isFocused, setIsFocused] = useState(false)

  const dispatch = useDispatch()

  const handleUpdate = async () => {
    let body = {
      notebookHeading: songHeading
    }

    const { error } = await putMethod(
      token,
      Api.updateNotebook + '/' + singleNotebook?._id,
      body
    )

    if (!error) {
      const arr = await shiftUpdateSongElement(
        songs,
        songHeading,
        singleNotebook,
        'heading'
      )
      if (type === LeftRightPaneFolderTypes.NOTES) {
        dispatch(allSongAction(arr, title, '', LeftRightPaneFolderTypes.NOTES))
      }
    }
    singleSong && isFocused && error && toast.error(error)
  }

  React.useEffect(() => {
    if (!isFocused) return
    const delayDebounceFn = setTimeout(() => {
      handleUpdate()
      // Send Axios request here
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [isFocused, songHeading])

  const disableEditor = useMemo(() => {
    if (type === LeftRightPaneFolderTypes.NOTES) {
      return false
    }
    return true
  }, [type])

  const showHeading = useMemo(() => {
    if (singleSong || singleNotebook) {
      return true
    }
    if (singleSong) {
      return true
    }
    return false
  }, [singleSong, singleNotebook])

  useEffect(() => {
    if (singleNotebook && singleSong === null) {
      setSongHeading(singleNotebook?.notebookHeading || '')
    } else {
      singleSong && setSongHeading(singleSong?.songHeading || '')
    }
    setIsFocused(false)
  }, [singleSong, singleNotebook, type])

  return (
    <FormControl fullWidth>
      {showHeading && (
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          disableunderline={false}
          // label="Phone Number"
          name="songHeading"
          classes={{ notchedOutline: classes.input }}
          onClick={() => setIsFocused(true)}
          onChange={e => {
            setSongHeading(e.target.value)
          }}
          className={`${classes.textField} song_heading_edit border-0`}
          value={songHeading}
          placeholder="Enter heading..."
          InputProps={{
            classes: { notchedOutline: classes.noBorder }
          }}
          onBlur={() => handleUpdate()}
          disabled={disableEditor}
        />
      )}
    </FormControl>
  )
}

export default EditorSongHeading
