import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { getFavoriteTagsReducer } from './cartTab/cartTab.reducer'
import { allFoldersReducer, selectedFolderReducer } from './folder.reducer'
import {
  ideaReducer,
  ideaSearchDataReducer,
  lookUpReducer
} from './idea/idea.reducer'
import { loaderReducer } from './loader.reducer'
import {
  backTrackFooterReducer,
  backTrackPlayPauseReducer,
  backTrackSelectBufferDataReducer,
  backTrackSelectReducer,
  getBackTrackReducer
} from './mediaPlayerReducers/backTrack.reducer'
import {
  blockRecordingReducer,
  getRecordingReducer,
  playPauseReducer
} from './mediaPlayerReducers/mediaPlayer.reducer'
import {
  allSongReducer,
  recentSongReducer,
  singleSongReducer
} from './song.reducer'
import {
  addFolderToggleReducer,
  addSongBlockToggleReducer,
  addSongToggleReducer,
  backgroundColorToggleReducer,
  colorToggleToggleReducer,
  leftSidebarToggleReducer,
  rightSidebarToggleReducer,
  selectButtonToggleHideReducer,assistantTabToggleReducer,addNotebookToggleReducer
} from './toggle.reducer'
import { firstChordReducer, secondChordReducer } from './chords/chord.reducer'
const rootReducer = combineReducers({
  loader: loaderReducer,
  auth: authReducer,
  // toggle left pane, right pane and add/edit folders and songs
  leftToggle: leftSidebarToggleReducer,
  rightToggle: rightSidebarToggleReducer,
  folderToggle: addFolderToggleReducer,
  songToggle: addSongToggleReducer,
  songBlockToggle: addSongBlockToggleReducer,
  assistantTab :assistantTabToggleReducer,


  // font color and background color toggle
  colorToggle: colorToggleToggleReducer,
  backColorToggle: backgroundColorToggleReducer,

  // song select toggle song/folder
  selectBtnToggle: selectButtonToggleHideReducer,

  // song
  singleSong: singleSongReducer,
  // notebook

  notebook:addNotebookToggleReducer,

  // folders
  selectedFolders: selectedFolderReducer,

  // API data
  recentSongs: recentSongReducer,
  songs: allSongReducer,
  folders: allFoldersReducer,

  // custom media player
  liveSongList: getRecordingReducer,
  playPause: playPauseReducer,

  // song block
  isBlockRecording: blockRecordingReducer,

  // back track states
  allBackTracks: getBackTrackReducer,
  selectBackTrack: backTrackSelectReducer,
  backTrackState: backTrackPlayPauseReducer,
  isBackTrackEnableState:backTrackFooterReducer,

  // Ideas
  ideaList: ideaReducer,
  lookUpToggle: lookUpReducer,
  ideaSearchData: ideaSearchDataReducer,

  // favorite tags state
  favTags: getFavoriteTagsReducer,
  backTrackBuffer: backTrackSelectBufferDataReducer,
  firstChord:firstChordReducer,
  secondChord:secondChordReducer,
})

export default rootReducer
