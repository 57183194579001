import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { a11yProps, TabPanel } from '../../../utils/Tabs'
import BasicChoord from './BasicChord'
import Progressions from './Progressions'
import SliderTab from './SliderTab'
import { chordsWheel2 } from '../../../utils/chords'
import { useDispatch } from 'react-redux'
import { secondChordAction } from '../../../services/actions/chords/chord.action'
import { useSelector } from 'react-redux'
import { AddIcon } from '../../../helper/icons'
import {
  musicSymbolsCodes,
  musicSymbolsCodesWheel2
} from '../../../utils/music.symbol.utils'

const ChordNotation = () => {
  const [value, setValue] = React.useState(0)
  const dispatch = useDispatch()

  const { secondChord } = useSelector(state => state.secondChord)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [chordNotationList, setChordNotationList] = useState(chordsWheel2)

  return (
    <>
      <div>
        <div className="gray-section-right-bar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="right-side-tab mt-2">
                  <Box sx={{ width: '100%' }}>
                    <Box className="tabs-outer-bg">
                      <Tabs
                        className="round-blue"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          className="right-tab-custom chordnotion-tab"
                          label="Basic Chords"
                          {...a11yProps(0)}
                        />
                        {/* <Tab
                          className="right-tab-custom chordnotion-tab"
                          label="Progressions"
                          {...a11yProps(1)}
                        /> */}
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <div className="container">
                        <div className="row mt-2">
                          <div className="col-lg-12 text-center">
                            <p className="m-0">Chord Notation</p>
                          </div>
                        </div>
                      </div>
                      <SliderTab />
                      <div className="row mt-4">
                        <div className="col-lg-12 text-center">
                          <div className="buttons-right-bar top">
                            <ul className="mb-0">
                              {/* {chordNotationList?.map((chord, index) => ( */}
                              {musicSymbolsCodesWheel2?.map((chord, index) => (
                                <li
                                  className={`right-bar-btn-list ${
                                    secondChord == chord && 'active'
                                  }`}
                                  key={index}
                                  onClick={() =>
                                    dispatch(secondChordAction(chord))
                                  }
                                >
                                  {chord}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-12 text-center">
                          <div className="add-btn-right-bar new-right">
                            <AddIcon className={`pr-2 add-icon`} />

                            <span>Add</span>
                          </div>
                        </div>
                      </div> */}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className="container">
                        <div className="row mt-4">
                          <div className="col-lg-12 text-center">
                            <p className="m-0">Chord Notation</p>
                          </div>
                        </div>
                      </div>
                      <SliderTab />
                      <div className="row mt-4">
                        <div className="col-lg-12 text-center">
                          <div className="buttons-right-bar">
                            <ul className="mb-0">
                              {chordNotationList?.map((chord, index) => (
                                <li className="right-bar-btn-list" key={index}>
                                  {chord}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <div className="add-btn-right-bar">
                            {/* <img
                              className="add-btn-right-bar"
                              src="assets/images/add_icon.svg"
                              alt=""
                            /> */}

                            <AddIcon
                              className={`pr-2 add-icon`}
                              // clickFun={() => !disabled && handleClick()}
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabPanel className="" value={value} index={0}>
          <BasicChoord />
        </TabPanel>
        <TabPanel className="" value={value} index={1}>
          <Progressions />
        </TabPanel>
      </div>
    </>
  )
}

export default ChordNotation
