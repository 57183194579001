import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRecordingAction,
  playPauseAction
} from '../../../services/actions/mediaPlayerActions/mediaPlayer.action'

import { loaderAction } from './../../../services/actions/loader.action'
import { UseAlert } from '../../commonComponent/commonComponent'
import { deleteMethod, getMethod } from '../../../helper/ApiMethods'
import { Api } from '../../../helper/ApiList'
import { useAlert } from 'react-alert'
import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import Tooltip from '@mui/material/Tooltip'
import {
  backTrackPlayPauseAction,
  backTrackSelectAction
} from '../../../services/actions/mediaPlayerActions/backTrack.action'
import RecordingList from './RecordingList'
import VolumeBar from '../../Player/VolumeBar'
import { playerConstants } from '../../../services/constants'
import { LeftRightPaneFolderTypes } from '../../../utils/commonOptions'
import { toast } from '../../Toast/Toast'
import RecordingFilter from './RecordingFilter'

const RecordingTab = () => {
  const { isPlaying, activeSong, playerVolume } = useSelector(
    state => state.playPause
  )
  const { liveSongList } = useSelector(state => state.liveSongList)
  const { backTrackActiveSong } = useSelector(state => state.backTrackState)
  const { type } = useSelector(state => state.songs)
  const { assistantTab } = useSelector(state => state.assistantTab)

  const { singleSong } = useSelector(state => state.singleSong)
  const { token } = useSelector(state => state.auth)
  const { isBlockRecording } = useSelector(state => state.isBlockRecording)
  const [loaderId, setLoaderId] = useState([])

  const [recordingList, setRecordingList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [selectSongs, setSelectSongs] = useState([])

  const dispatch = useDispatch()

  const getUrl = async song => {
    dispatch(playPauseAction(true, song))
  }

  const handlePlayPause = async song => {
    if (isBlockRecording) {
      toast.info('Recording is already running...')
      return
    }

    if (backTrackActiveSong?.title) {
      dispatch(backTrackSelectAction(false, null)) // if track select is true then back track is automatically play
      dispatch(backTrackPlayPauseAction(false, null))
    }

    dispatch(loaderAction(true))

    const isSameSong = activeSong?._id === song?._id

    if (isSameSong && isPlaying) {
      dispatch(playPauseAction(false, activeSong))
    } else {
      if (!isSameSong) {
        dispatch(playPauseAction(false, null))
      }

      setTimeout(
        async () => {
          await getUrl(song)
        },
        isPlaying ? 10 : 50
      )
    }

    dispatch(loaderAction(false))
  }

  const handleSongSelect = songItem => {
    if (selectSongs.includes(songItem?._id)) {
      let temp = selectSongs?.filter(item => item !== songItem?._id)
      setSelectSongs(temp)
    } else {
      setSelectSongs(pre => [...pre, songItem?._id])
    }
  }
  const handleExportSelect = songItem => {
    if (selectSongs.includes(songItem?._id)) {
      let temp = selectSongs?.filter(item => item !== songItem?._id)
      setSelectSongs(temp)
    } else {
      setSelectSongs(pre => [...pre, songItem?._id])
    }
  }

  const confirmDeleteSongs = async () => {
    if (selectSongs.includes(activeSong?._id)) {
      dispatch(playPauseAction(false))
    }
    dispatch(loaderAction(true))
    const filteredValidItems = recordingList.filter(item => {
      return selectSongs.includes(item?._id) && item
    })
    const filteredSongItems = recordingList.filter(item => {
      return !selectSongs.includes(item?._id) && item
    })

    const filteredItems = filteredValidItems.map(item => {
      return {
        fileName: item.keyName,
        recordingId: item?._id
      }
    })

    const body = {
      recordingFileData: filteredItems
    }
    const url = Api.deleteRecordings + singleSong?._id

    try {
      const { data, error } = await deleteMethod(token, url, body)

      if (!error) {
        toast.success(data?.message)
        let obj = {
          recordingList: filteredSongItems
        }

        setRecordingList(filteredSongItems)
        setSelectSongs([])
      }
    } catch (error) {
      console.log(error, 'recordings song deleted error')
    }
    dispatch(loaderAction(false))

    setIsOpen(false)
  }

  const handleComplete = (data, url, name) => {
    saveAs(url, name)
    setTimeout(() => {
      let temp = loaderId?.filter(item => item !== name)
      setLoaderId(temp)
    }, 3000)
  }

  const handleParse = async (url, name) => {
    // setLoaderId(true)
    setLoaderId(pre => [...pre, name])

    Papa.parse(url, {
      download: true,
      complete: ({ data }) => handleComplete(data, url, name)
    })
  }

  const handleExport = () => {
    if (selectSongs?.length > 0) {
      for (const item of liveSongList) {
        for (const id of selectSongs) {
          if (item?._id === id) {
            // FileSaver.saveAs(item?.songLink, item.songName)
            // let url = Api.getFileInfo + '?key=' + item?.keyName + '.mp3';
            let url = `${Api.getFileInfo}/${item?._id}`

            handleParse(url, item.songName)
            // handleExportBackTrackAndRecordings(item?.songLink, item.songName)
          }
        }
      }
    }
  }

  const handleVolume = event => {
    dispatch({
      type: playerConstants.PLAYER_VOLUME,
      payload: { playerVolume: event.target.value }
    })
  }

  const handleRefresh = async () => {
    dispatch(loaderAction(true))

    let url = Api.getRecordings + singleSong?._id
    const { data } = await getMethod(token, url)

    dispatch(getRecordingAction(data))

    dispatch(loaderAction(false))
  }

  useEffect(() => {
    let temp = liveSongList?.filter(item => item?.isDeleted !== true)
    setRecordingList(temp)
  }, [liveSongList])

  return (
    <>
      <section className="outer-bg-volume">
        <UseAlert
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          confirmDelete={confirmDeleteSongs}
        />
        <div className="container">
          <div className="row">
            <div className="col-1 m-0 p-0 mt-1">
              <div className="edit-icon-right-bar">
                <Tooltip title="Edit Music" arrow>
                  <img
                    className="cursor"
                    src="assets/images/edit-music-icon-new.svg"
                    alt=""
                    width={24}
                    onClick={() => setIsEdit(!isEdit)}
                  />
                </Tooltip>
              </div>
            </div>

            <div className="col-8">
              <div className="sound-volume recording-tab">
                <VolumeBar
                  value={playerVolume}
                  min="0"
                  max="1"
                  onChange={event => handleVolume(event)}
                  className="backTrackVolume"
                />
              </div>
            </div>

            <div
              className="col-2 p-0 text-center d-flex"
              style={{ marginLeft: '' }}
            >
              <div className="share-and-delete d-flex mt-0">
                <Tooltip title="Export" arrow>
                  <img
                    onClick={() => handleExport()}
                    className="cursor"
                    src="assets/images/share-song-new.svg"
                    alt=""
                    width={26}
                  />
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <img
                    onClick={() => {
                      selectSongs.length > 0 && setIsOpen(true)
                    }}
                    className="ml-3 cursor"
                    src="assets/images/trash-song-new.svg"
                    alt=""
                    width={23}
                  />
                </Tooltip>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="row mt-2 ps-2 pe-2">
              <div className="col-6">
                <div className="script-name recording d-flex">
                  <h6>My Recordings </h6>
                  <img
                    className="loader-img"
                    src="assets/images/rotate-left-icon-new.svg"
                    alt=""
                    onClick={() => handleRefresh()}
                  />
                </div>
              </div>
              <div className="col-6 text-end select-btn">
                <div>
                  <RecordingFilter type={'recording'} />
                </div>
              </div>
            </div>
            <div className="custom-table-right-bar tableWrap">
              <div className="table">
                <div className="recording-header">
                  <p>Title & Time</p>
                  {/* <th scope="col">Time</th> */}
                  <p>Date</p>
                  {/* {isDeleteActive ||
                      (exportBtnActive && <th scope="col">Action</th>)} */}
                </div>

                <div
                  className={`rec_list ${
                    singleSong &&
                    !assistantTab &&
                    type !== LeftRightPaneFolderTypes.NOTES
                      ? ''
                      : 'hide_footer'
                  }`}
                >
                  {recordingList?.length === 0 && (
                    <div className="empty-song-list">No records found</div>
                  )}
                  {recordingList?.map((songItem, index) => (
                    <React.Fragment key={index}>
                      <RecordingList
                        index={index}
                        handlePlayPause={handlePlayPause}
                        selectSongs={selectSongs}
                        songItem={songItem}
                        handleSongSelect={handleSongSelect}
                        loaderId={loaderId}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        recordingList={recordingList}
                        setRecordingList={setRecordingList}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RecordingTab
