/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react'
import { PreSelectMenu } from '../commonComponent/SelectMenu'
import { useSelector } from 'react-redux'

import {
  LeftRightPaneFolderTypes,
  aiAssistant,
  hideTabs,
  windowFeatures
} from '../../utils/commonOptions'
import { getMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { ButtonLoader } from '../../Loader/ButtonLoader'
import { useDispatch } from 'react-redux'
import { assistantFooterDeleteAction } from '../../services/actions/toggle.action'
import { useEffect } from 'react'
import { covertHtmlToPlainText } from '../../helper/Email'
import OptionMenu from './OptionMenu'
import AssistantForm from './AssistantForm'
import { toast } from '../Toast/Toast'
import AIIdeasForm from './AIIdeasForm'

const AIAssistant = () => {
  const dispatch = useDispatch()
  const { type } = useSelector(state => state.songs)
  const { singleSong, singleNotebook } = useSelector(state => state.singleSong)

  const { token } = useSelector(state => state.auth)
  const { ai_generated, deleteAction } = useSelector(
    state => state.assistantTab
  )

  const [expand, setExpand] = useState('panel1')
  const [btnLoader, setBtnLoader] = useState(false)
  const [aiLoader, setAILoader] = useState(false)
  const [counts, setCounts] = useState(0)
  const [allLyrics, setAllLyrics] = useState([])
  const [values, setValues] = useState(aiAssistant)
  const [aContent, setAiContent] = useState('')

  const newIdeas = sessionStorage.getItem('new_ideas')

  const option = lyric => {
    return lyric?.aiAssistantLyricsQuestion?.includes(
      "You're a professional songwriter who writes creative"
    )
      ? lyric?.theme
      : lyric?.aiAssistantLyricsQuestion
  }
  useMemo(() => setAiContent(''), [type, newIdeas])

  const wordCounts = useMemo(() => {
    const num =
      Number(counts?.wordBalance || 0) - Number(counts?.usedWordBalance || 0)
    var usFormat = num?.toLocaleString('en-US')
    return { withComma: usFormat, wordCount: num }
  }, [counts])

  const getHistoryOfLyrics = async () => {
    try {
      let url = Api.getAllAILyricsWithOutFilter
      setValues(aiAssistant)
      setAILoader(true)
      const { data } = await getMethod(token, url)
      setAllLyrics(data?.lyrics)

      deleteAction && dispatch(assistantFooterDeleteAction(false))
    } catch (error) {}
    setAILoader(false)
    setAiContent('')
  }

  useEffect(() => {
    const getWordLimitCounts = async () => {
      try {
        const { data } = await getMethod(token, Api.wordLimit)
        setCounts(data)
      } catch (error) {}
    }

    token && getHistoryOfLyrics()
    token && getWordLimitCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAction, token, singleNotebook])

  useEffect(() => {
    if (ai_generated) {
      const inputs = ai_generated?.inputs || ai_generated
      setValues({
        theme: inputs?.theme,
        style: inputs?.style,
        genre: inputs?.genre,
        verses: inputs?.verses || inputs?.count,
        typeOfIdeas: inputs?.typeOfIdeas || '',
        pre_chorus: inputs?.pre_chorus,
        chorus: inputs?.chorus,
        bridge: inputs?.bridge,
        outro: inputs?.outro,
        lang: inputs?.lang || 'English',
        isTitle: inputs?.isTitle || false
      })
    } else {
      setValues(aiAssistant)
    }
  }, [ai_generated])

  let totalWords = useMemo(() => {
    return {
      words: ai_generated?.content?.split(' '),
      characters: covertHtmlToPlainText(ai_generated?.content || '')
    }
  }, [ai_generated?.content])

  const handleBuyMore = () => {
    const handle = window.open('/price', 'Price Page', windowFeatures)
    if (!handle) {
      toast.error(
        "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
      )
    }
  }

  const heading = () => {
    if (ai_generated) {
      let value = ai_generated?.inputs || ai_generated
      if (value?.typeOfIdeas && value?.isTitle) {
        return `Here are ${value?.genre} inspired ${value?.typeOfIdeas} that incorporate the theme ${value?.theme}:`
      }
    }

    return ''
  }

  const openReferral = e => {
    e.preventDefault()
    const windowFeatures = 'left=250,top=100,width=750,height=700'
    const handle = window.open('/referral', 'Price Page', windowFeatures)
    if (!handle) {
      window.alert(
        "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
      )
    }
  }

  return (
    <>
      {/* buy more header */}
      <div className="row">
        <div
          className={`col-12 ai-header-wrapper ${
            type === LeftRightPaneFolderTypes.AI_ASSISTANT && 'mt-2'
          }`}
        >
          <div className="French-Dreams-text">
            <h5 className="m-0">{singleSong?.songHeading || ''}</h5>
          </div>
          <div className="balance-reobon ">
            <span className="earn-token" onClick={openReferral}>
              <img className="" src="assets/images/icon_AI@2x.png" alt="" />
              Earn Tokens
            </span>{' '}
            <span className="right-line"></span>
            <span>My Balance: {wordCounts?.withComma || 0} </span>{' '}
            <a href="#" onClick={() => handleBuyMore()}>
              {' '}
              Buy More
            </a>
          </div>
        </div>
      </div>

      {/* collapsable form */}

      {type === LeftRightPaneFolderTypes.NOTES ||
      newIdeas ||
      values?.isTitle ? (
        <>
          <AIIdeasForm
            values={values}
            setValues={setValues}
            btnLoader={btnLoader}
            wordCounts={wordCounts}
            setBtnLoader={setBtnLoader}
            setCounts={setCounts}
            setAiContent={setAiContent}
            aContent={aContent}
            expand={expand}
            setExpand={setExpand}
          />
          Ideas
        </>
      ) : (
        <AssistantForm
          values={values}
          setValues={setValues}
          btnLoader={btnLoader}
          wordCounts={wordCounts}
          setBtnLoader={setBtnLoader}
          setCounts={setCounts}
          setAiContent={setAiContent}
          aContent={aContent}
          expand={expand}
          setExpand={setExpand}
        />
      )}

      {/* AI GENERATED contents */}
      <div className="outer-accr">
        <div className="row">
          <div className="col-6">
            <div className="">
              <p className="select-lyrics">{option(ai_generated) || ''}</p>
              <p
                className={`m-0 inspiration pe-2 ${
                  ai_generated?.theme ? '' : 'isNot'
                }`}
              >
                Access previously generated{' '}
                {type === LeftRightPaneFolderTypes.NOTES || newIdeas
                  ? 'ideas'
                  : 'lyrics'}
                <span className="position-relative ps-4">
                  {aiLoader && <ButtonLoader />}
                </span>
              </p>
            </div>
          </div>
          <div className="col-6">
            <PreSelectMenu allLyrics={allLyrics} setValues={setValues} />
          </div>
        </div>
      </div>
      {ai_generated && (
        <>
          <div className="start-box">
            <div className="row mt-2 ">
              <div className="col-lg-4">
                <div className="SP_AI_Generated">
                  <img
                    className="star_blue_Img"
                    src="assets/images/star-ai-accor.svg"
                    alt=""
                  />
                  <p className="mb-0">SP AI Generated</p>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="drop-text-ai d-flex align-items-center justify-content-between mt-1">
                  <p className="mb-0">
                    {totalWords?.words?.length || 0} Words /{' '}
                    {totalWords?.characters?.length || 0} Characters
                  </p>
                  <OptionMenu
                    allLyrics={allLyrics}
                    setAllLyrics={setAllLyrics}
                  />
                </div>
              </div>
              <div className="col-12">
                <p className="m-0 fs-14 fw-500">{heading()}</p>
              </div>
            </div>
          </div>
          <div
            className={`ai-content-wrapper ${
              hideTabs?.includes(type) && 'hide-tabs'
            }`}
          >
            <p
              className={`ai-content ${
                expand ? `ai-expand ${values?.isTitle ? 'ideas' : ''}` : ''
              } ${
                type === LeftRightPaneFolderTypes.NOTES || newIdeas
                  ? 'note_ai'
                  : ''
              }`}
              dangerouslySetInnerHTML={{
                __html: ai_generated?.content?.replaceAll(`\n`, `</br>`)
              }}
            ></p>
          </div>
        </>
      )}
    </>
  )
}

export default AIAssistant
