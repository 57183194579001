import React, { useEffect, useMemo } from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import TabContext from '@mui/lab/TabContext'
import Box from '@mui/material/Box'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Tab from '@mui/material/Tab'
import SongEditor from './SongEditor'
import SongBlocks from './SongBlock/SongBlocks'
import EditorSongHeading from './EditorSongHeading'
import { useSelector } from 'react-redux'
import { getMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { useDispatch } from 'react-redux'
import { loaderAction } from '../../services/actions/loader.action'
import { songAction } from '../../services/actions/song.action'

import AIAssistant from '../AIAssistant/AIAssistant'
import { assistantFooterToggleAction } from '../../services/actions/toggle.action'
import { LeftRightPaneFolderTypes, hideTabs } from '../../utils/commonOptions'
import NotebookHeader from './NotebookHeader'
import { useState } from 'react'

// const MainPane = ({ editor, setIsFullToolbar }) => {
const MainPane = forwardRef((props, ref) => {
  // const MainPane = forwardRef({ editor, setIsFullToolbar }, ref) => {
  const blockRef = useRef(null)

  const { ai_generated } = useSelector(state => state.assistantTab)
  let { editor, setIsFullToolbar } = props

  const { rightToggle } = useSelector(state => state.rightToggle)
  const { leftToggle } = useSelector(state => state.leftToggle)
  const { type } = useSelector(state => state.songs)
  const { singleSong, singleNotebook } = useSelector(state => state.singleSong)
  const { token } = useSelector(state => state.auth)

  const [selectedNotebook, setSelectedNotebook] = useState(null)

  // this function is used to send the ref from child to parent
  useImperativeHandle(ref, () => ({
    childFunction1() {
      let data = blockRef?.current?.childFunction1()
      return data
    }
  }))

  const sidebarToggle = useMemo(() => {
    return leftToggle && rightToggle ? 'rightToggle' : ''
  }, [leftToggle, rightToggle])

  const leftSidebarToggle = useMemo(() => {
    return !leftToggle && rightToggle ? 'leftToggle' : ''
  }, [leftToggle, rightToggle])

  const hideSidebarToggles = useMemo(() => {
    return !leftToggle && !rightToggle ? 'hideToggles' : ''
  }, [leftToggle, rightToggle])

  const dispatch = useDispatch()
  const [value, setValue] = React.useState('1')
  const handleChange = async (event, newValue) => {
    setValue(newValue)
    dispatch(assistantFooterToggleAction(false, null))
    if (newValue == 1) {
      await getSongBlocks()
    } else if (newValue == 3) {
      setIsFullToolbar(true)
    } else {
      setIsFullToolbar(false)
    }
  }

  const handleAssistantTab = boolean => {
    dispatch(assistantFooterToggleAction(boolean, ai_generated))
  }

  useEffect(() => {
    dispatch(assistantFooterToggleAction(false, null))
    if (type === LeftRightPaneFolderTypes.NOTES) {
      setValue('1')
      dispatch(assistantFooterToggleAction(false, null))
      return
    }
    if (type === LeftRightPaneFolderTypes.AI_ASSISTANT) {
      setValue('2')
      dispatch(assistantFooterToggleAction(true, ai_generated))
      return
    }

    !type && setValue('1')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const hideNote = useMemo(() => {
    if (type === LeftRightPaneFolderTypes.NOTES && singleNotebook) {
      return true
    }
    if (type !== LeftRightPaneFolderTypes.NOTES && singleNotebook?.isNotebook) {
      return true
    }
    return false
  }, [type, singleNotebook])

  const getSongBlocks = async () => {
    if (singleSong?._id) {
      let url = Api.getSongBlock + singleSong?._id
      const { data, rest } = await getMethod(token, url)

      rest?.songDetails && dispatch(songAction(rest.songDetails, data))
    }
    dispatch(loaderAction(false))
  }

  return (
    <TabContext value={value}>
      <div className="main-tab-content-wrapper">
        <div
          className={`cuatom-tab-bar d-flex align-items-center justify-content-center ${
            !singleSong && !singleNotebook && 'd-none'
          } ${hideTabs?.includes(type) && 'd-none'}`}
        >
          {type === LeftRightPaneFolderTypes.NOTES ? (
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Notebook"
                value="1"
                className={'custom_tab'}
                onClick={() => {
                  setIsFullToolbar(true)
                  handleAssistantTab(false)
                }}
              />
              <Tab
                label="AI Assistant"
                value="2"
                className={'custom_tab'}
                onClick={() => {
                  handleAssistantTab(true)
                  setIsFullToolbar(false)
                }}
              />
            </TabList>
          ) : (
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Song"
                value="1"
                className={'custom_tab'}
                onClick={() => {
                  setIsFullToolbar(false)
                  handleAssistantTab(false)
                }}
              />
              <Tab
                label="AI Assistant"
                value="2"
                className={'custom_tab'}
                onClick={() => {
                  handleAssistantTab(true)
                  setIsFullToolbar(false)
                }}
              />
              <Tab
                label="Notebook"
                value="3"
                className={'custom_tab'}
                onClick={() => {
                  setIsFullToolbar(true)
                  handleAssistantTab(false)
                }}
              />
            </TabList>
          )}
        </div>

        {type !== LeftRightPaneFolderTypes.NOTES ? (
          <>
            <TabPanel
              value="1"
              className={`p-0 pe-3 ${
                sidebarToggle ? 'song-block-wrapper' : ''
              }`}
            >
              {singleSong && <SongBlocks ref={blockRef} />}
            </TabPanel>

            <TabPanel
              value="2"
              className={`ps-0 ai-assistant-main ${
                type === LeftRightPaneFolderTypes.AI_ASSISTANT && 'ai_only'
              } ${leftToggle ? 'leftToggle' : ''} ${hideSidebarToggles}`}
            >
              <AIAssistant />
            </TabPanel>

            <TabPanel
              value="3"
              className={`p-0 ${
                leftSidebarToggle && 'ps-0 note-book-wrapper'
              } ${hideSidebarToggles} ${
                type === LeftRightPaneFolderTypes.NOTES
                  ? 'pt-3 notebook-tab-active'
                  : ''
              } `}
            >
              <NotebookHeader
                setSelectedNotebook={setSelectedNotebook}
                selectedNotebook={selectedNotebook}
              />

              {singleSong && <div className="m-0 mt-2 mb-2 p-0 line"></div>}
              <div className="row">
                <div
                  className={`col-lg-12 ${
                    hideTabs?.includes(type) ? 'full_editor' : 'mini_editor'
                  } `}
                >
                  {hideNote && <SongEditor editor={editor} />}
                </div>
              </div>
            </TabPanel>
          </>
        ) : (
          <>
            <TabPanel
              value="2"
              className={`ps-0 ai-assistant-main ${
                type === LeftRightPaneFolderTypes.AI_ASSISTANT && 'ai_only'
              } ${leftToggle ? 'leftToggle' : ''} ${hideSidebarToggles}`}
            >
              <AIAssistant />
            </TabPanel>

            <TabPanel
              value="1"
              className={`p-0 ${
                leftSidebarToggle && 'ps-0 note-book-wrapper'
              } ${hideSidebarToggles} ${
                type === LeftRightPaneFolderTypes.NOTES
                  ? 'pt-3 notebook-tab-active'
                  : ''
              } `}
            >
              <NotebookHeader
                setSelectedNotebook={setSelectedNotebook}
                selectedNotebook={selectedNotebook}
              />

              {singleSong && <div className="m-0 mt-2 mb-2 p-0 line"></div>}
              <div className="row">
                <div
                  className={`col-lg-12 ${
                    hideTabs?.includes(type) ? 'full_editor' : 'mini_editor'
                  } `}
                >
                  {hideNote && <SongEditor editor={editor} />}
                </div>
              </div>
            </TabPanel>
          </>
        )}
      </div>
    </TabContext>
  )
})

export default MainPane
