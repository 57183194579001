import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { limitAlert, windowFeatures } from '../../utils/commonOptions'
import { toast } from '../Toast/Toast'

const ConfirmModal = ({
  isOpen,
  setIsOpen,
  confirmDelete,
  message,
  heading
}) => {
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleBuyMore = () => {
    setIsOpen(false)
    const handle = window.open('/price', 'Price Page', windowFeatures)
    if (!handle) {
      toast.error(
        "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
      )
    }
  }
  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="custom_modal">
          {heading || ''}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || ''}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          {limitAlert?.[75] !== heading && (
            <Button onClick={confirmDelete} color="success">
              Continue Anyway
            </Button>
          )}
          <Button onClick={handleBuyMore} color="warning">
            Get More Tokens
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmModal
