const IdeaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M16.8927 1.22809L16.0156 2.10528M1.10327 1.22809L1.98046 2.10528M16.8927 13.5088L16.0156 12.6316M1.10327 13.5088L1.98046 12.6316M6.36643 15.2632H11.6296M7.24362 17.8948H10.7524M8.99801 2.10528C5.48924 2.10528 3.69275 3.81581 3.73485 6.49125C3.75503 7.79563 4.17345 8.68423 5.05064 9.56142C5.92783 10.4386 6.36643 10.8772 6.36643 12.6316H11.6296C11.6296 10.8772 12.0682 10.4386 12.9454 9.56142C13.8226 8.68423 14.241 7.79563 14.2612 6.49125C14.3033 3.81581 12.5068 2.10528 8.99801 2.10528Z"
        stroke="#2092EB"
        stroke-width="1.31579"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
const FavIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M5.75661 20.1667C7.33614 20.1667 8.61661 18.8862 8.61661 17.3067C8.61661 15.7272 7.33614 14.4467 5.75661 14.4467C4.17707 14.4467 2.89661 15.7272 2.89661 17.3067C2.89661 18.8862 4.17707 20.1667 5.75661 20.1667Z"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1035 7.29672V4.21674C19.1035 1.83342 17.6093 1.50342 16.0968 1.91592L10.3768 3.47424C9.33182 3.75841 8.61682 4.5834 8.61682 5.77506V7.76422V9.10254V17.2975"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1034 15.4V11"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2432 18.2599C17.8227 18.2599 19.1032 16.9795 19.1032 15.4C19.1032 13.8204 17.8227 12.54 16.2432 12.54C14.6636 12.54 13.3832 13.8204 13.3832 15.4C13.3832 16.9795 14.6636 18.2599 16.2432 18.2599Z"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.61682 8.72668L19.1035 5.8667"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const RecordingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 22 24"
      fill="none"
    >
      <path
        d="M11 17.4166C14.0342 17.4166 16.5 14.9508 16.5 11.9166V7.33334C16.5 4.29919 14.0342 1.83337 11 1.83337C7.96583 1.83337 5.5 4.29919 5.5 7.33334V11.9166C5.5 14.9508 7.96583 17.4166 11 17.4166Z"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.75 10.0834V11.9167C2.75 16.4725 6.44417 20.1666 11 20.1666C15.5558 20.1666 19.25 16.4725 19.25 11.9167V10.0834"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.002 20.4165V23.1665"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.35095 6.85666C9.98262 6.26083 11.761 6.26083 13.3926 6.85666"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.19421 9.60676C10.2942 9.30426 11.4584 9.30426 12.5584 9.60676"
        stroke="#2092EB"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const CartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M1.83301 1.83331H3.42802C4.41803 1.83331 5.1972 2.68581 5.1147 3.66665L4.35386 12.7966C4.22552 14.2908 5.40802 15.5741 6.91136 15.5741H16.6739C17.9939 15.5741 19.1489 14.4925 19.2498 13.1817L19.7448 6.30665C19.8548 4.78498 18.6998 3.54748 17.1689 3.54748H5.3347"
        stroke="#2092EB"
        stroke-width="1.375"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8957 20.1667C15.5285 20.1667 16.0416 19.6537 16.0416 19.0208C16.0416 18.388 15.5285 17.875 14.8957 17.875C14.2629 17.875 13.7499 18.388 13.7499 19.0208C13.7499 19.6537 14.2629 20.1667 14.8957 20.1667Z"
        stroke="#2092EB"
        stroke-width="1.375"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.56259 20.1667C8.19542 20.1667 8.70843 19.6537 8.70843 19.0208C8.70843 18.388 8.19542 17.875 7.56259 17.875C6.92976 17.875 6.41675 18.388 6.41675 19.0208C6.41675 19.6537 6.92976 20.1667 7.56259 20.1667Z"
        stroke="#2092EB"
        stroke-width="1.375"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.24988 7.33331H19.2499"
        stroke="#2092EB"
        stroke-width="1.375"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const AddBlueIcon = ({ handleAddFavoriteTags }) => {
  return (
    <>
      <img
        onClick={() => handleAddFavoriteTags && handleAddFavoriteTags()}
        src="assets/images/addIcon.png"
        alt="add"
      />
    </>
    // <svg
    //   onClick={() => handleAddFavoriteTags && handleAddFavoriteTags()}
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g clip-path="url(#clip0_50_4344)">
    //     <rect width="24" height="24" rx="4" fill="white" />
    //     <path
    //       d="M12 18V6"
    //       stroke="url(#paint0_linear_50_4344)"
    //       stroke-width="1.5"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     />
    //     <path
    //       d="M16 12H18"
    //       stroke="url(#paint1_linear_50_4344)"
    //       stroke-width="1.5"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     />
    //     <path
    //       d="M6 12H11.66"
    //       stroke="url(#paint2_linear_50_4344)"
    //       stroke-width="1.5"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     />
    //     <path
    //       d="M12 18V6"
    //       stroke="url(#paint3_linear_50_4344)"
    //       stroke-width="1.5"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     />
    //   </g>
    //   <defs>
    //     <linearGradient
    //       id="paint0_linear_50_4344"
    //       x1="12"
    //       y1="12"
    //       x2="13"
    //       y2="12"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stop-color="#1C82D1" />
    //       <stop offset="1" stop-color="#2092EB" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint1_linear_50_4344"
    //       x1="16"
    //       y1="12.5"
    //       x2="18"
    //       y2="12.5"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stop-color="#1C82D1" />
    //       <stop offset="1" stop-color="#2092EB" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint2_linear_50_4344"
    //       x1="6"
    //       y1="12.5"
    //       x2="11.66"
    //       y2="12.5"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stop-color="#1C82D1" />
    //       <stop offset="1" stop-color="#2092EB" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint3_linear_50_4344"
    //       x1="12"
    //       y1="12"
    //       x2="13"
    //       y2="12"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stop-color="#1C82D1" />
    //       <stop offset="1" stop-color="#2092EB" />
    //     </linearGradient>
    //     <clipPath id="clip0_50_4344">
    //       <rect width="24" height="24" rx="4" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  )
}

const AddMoreBlueIcon = ({ clickFun, width = 15, height = 14 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickFun}
    >
      <g clip-path="url(#clip0_1_851)">
        <rect x="0.499023" width="14" height="14" rx="4" fill="#2092EB" />
        <path
          d="M7.49902 10.5V3.5"
          stroke="white"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.8324 7H10.9991"
          stroke="white"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.99902 7H7.30069"
          stroke="white"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.49902 10.5V3.5"
          stroke="white"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_851">
          <rect x="0.499023" width="14" height="14" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const DeleteIcon = ({ handleDelete }) => {
  return (
    <svg
      style={{ marginLeft: '5px' }}
      onClick={() => handleDelete()}
      width="18"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5373 2.46268C9.03146 -0.0431259 4.96854 -0.0431259 2.46273 2.46268C-0.0430781 4.96849 -0.0430783 9.03141 2.46273 11.5372C4.96854 14.043 9.03146 14.043 11.5373 11.5372C14.0431 9.03141 14.0431 4.96849 11.5373 2.46268ZM5.76256 9.06235C5.65317 9.17174 5.50479 9.2332 5.35008 9.2332C5.19537 9.2332 5.047 9.17174 4.93761 9.06235C4.82821 8.95295 4.76675 8.80458 4.76675 8.64987C4.76675 8.49516 4.82821 8.34679 4.93761 8.23739L6.17504 6.99995L4.93761 5.76252C4.82821 5.65312 4.76675 5.50475 4.76675 5.35004C4.76675 5.19533 4.82821 5.04695 4.93761 4.93756C5.047 4.82816 5.19537 4.7667 5.35008 4.7667C5.50479 4.7667 5.65317 4.82816 5.76256 4.93756L7 6.17499L8.23744 4.93756C8.34683 4.82816 8.49521 4.7667 8.64992 4.7667C8.80463 4.7667 8.953 4.82816 9.06239 4.93756C9.17179 5.04695 9.23325 5.19533 9.23325 5.35004C9.23325 5.50475 9.17179 5.65312 9.06239 5.76252L7.82496 6.99995L9.06239 8.23739C9.17179 8.34679 9.23325 8.49516 9.23325 8.64987C9.23325 8.80458 9.17179 8.95295 9.06239 9.06235C8.953 9.17174 8.80463 9.2332 8.64992 9.2332C8.49521 9.2332 8.34683 9.17174 8.23744 9.06235L7 7.82491L5.76256 9.06235Z"
        fill="#AFAFAF"
      />
    </svg>
  )
}

const DrumIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M2.75777 15.7812C4.11958 15.7812 5.22355 14.6772 5.22355 13.3154C5.22355 11.9536 4.11958 10.8496 2.75777 10.8496C1.39596 10.8496 0.291992 11.9536 0.291992 13.3154C0.291992 14.6772 1.39596 15.7812 2.75777 15.7812Z"
        fill="#2092EB"
      />
      <path
        d="M9.08736 21.2123C10.4492 21.2123 11.5531 20.1084 11.5531 18.7465C11.5531 17.3847 10.4492 16.2808 9.08736 16.2808C7.72555 16.2808 6.62158 17.3847 6.62158 18.7465C6.62158 20.1084 7.72555 21.2123 9.08736 21.2123Z"
        fill="#2092EB"
      />
      <path
        d="M16.5063 15.7812C17.8681 15.7812 18.9721 14.6772 18.9721 13.3154C18.9721 11.9536 17.8681 10.8496 16.5063 10.8496C15.1445 10.8496 14.0405 11.9536 14.0405 13.3154C14.0405 14.6772 15.1445 15.7812 16.5063 15.7812Z"
        fill="#2092EB"
      />
      <path
        d="M2.75787 15.5377C3.98517 15.5377 4.98009 14.5428 4.98009 13.3155C4.98009 12.0882 3.98517 11.0933 2.75787 11.0933C1.53057 11.0933 0.535645 12.0882 0.535645 13.3155C0.535645 14.5428 1.53057 15.5377 2.75787 15.5377Z"
        stroke="#2092EB"
        stroke-width="0.888889"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.08746 20.9689C10.3148 20.9689 11.3097 19.9739 11.3097 18.7466C11.3097 17.5193 10.3148 16.5244 9.08746 16.5244C7.86016 16.5244 6.86523 17.5193 6.86523 18.7466C6.86523 19.9739 7.86016 20.9689 9.08746 20.9689Z"
        stroke="#2092EB"
        stroke-width="0.888889"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5064 15.5377C17.7337 15.5377 18.7286 14.5428 18.7286 13.3155C18.7286 12.0882 17.7337 11.0933 16.5064 11.0933C15.2791 11.0933 14.2842 12.0882 14.2842 13.3155C14.2842 14.5428 15.2791 15.5377 16.5064 15.5377Z"
        stroke="#2092EB"
        stroke-width="0.888889"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2129 0.624512H18.5604L18.7284 13.3156M13.7937 6.05562H11.1409L11.3093 18.7467M7.46441 0.624512H4.81152L4.97997 13.3156"
        stroke="#2092EB"
        stroke-width="0.888889"
        strokeMiterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const AddIcon = ({ className, clickFun }) => {
  return (
    <svg
      className={className}
      onClick={clickFun}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_50_4344)">
        <rect width="24" height="24" rx="4" fill="white" />
        <path
          d="M12 18V6"
          stroke="url(#paint0_linear_50_4344)"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 12H18"
          stroke="url(#paint1_linear_50_4344)"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 12H11.66"
          stroke="url(#paint2_linear_50_4344)"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 18V6"
          stroke="url(#paint3_linear_50_4344)"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_50_4344"
          x1="12"
          y1="12"
          x2="13"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1C82D1" />
          <stop offset="1" stop-color="#2092EB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_50_4344"
          x1="16"
          y1="12.5"
          x2="18"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1C82D1" />
          <stop offset="1" stop-color="#2092EB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_50_4344"
          x1="6"
          y1="12.5"
          x2="11.66"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1C82D1" />
          <stop offset="1" stop-color="#2092EB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_50_4344"
          x1="12"
          y1="12"
          x2="13"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1C82D1" />
          <stop offset="1" stop-color="#2092EB" />
        </linearGradient>
        <clipPath id="clip0_50_4344">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const SuccessIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/bold/tick-circle">
        <g id="vuesax/bold/tick-circle_2">
          <g id="tick-circle">
            <path
              id="Vector"
              d="M11.9998 2.66663C6.85717 2.66663 2.6665 6.85729 2.6665 12C2.6665 17.1426 6.85717 21.3333 11.9998 21.3333C17.1425 21.3333 21.3332 17.1426 21.3332 12C21.3332 6.85729 17.1425 2.66663 11.9998 2.66663ZM16.4612 9.85329L11.1692 15.1453C11.0385 15.276 10.8612 15.3506 10.6745 15.3506C10.4878 15.3506 10.3105 15.276 10.1798 15.1453L7.5385 12.504C7.26784 12.2333 7.26784 11.7853 7.5385 11.5146C7.80917 11.244 8.25717 11.244 8.52784 11.5146L10.6745 13.6613L15.4718 8.86396C15.7425 8.59329 16.1905 8.59329 16.4612 8.86396C16.7318 9.13463 16.7318 9.57329 16.4612 9.85329Z"
              fill="#47B881"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const ErrorIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
        fill="#F64C4C"
      />
    </svg>
  )
}
const WarningIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM9.25 6C9.25 5.59 9.59 5.25 10 5.25C10.41 5.25 10.75 5.59 10.75 6V11C10.75 11.41 10.41 11.75 10 11.75C9.59 11.75 9.25 11.41 9.25 11V6ZM10.92 14.38C10.87 14.51 10.8 14.61 10.71 14.71C10.61 14.8 10.5 14.87 10.38 14.92C10.26 14.97 10.13 15 10 15C9.87 15 9.74 14.97 9.62 14.92C9.5 14.87 9.39 14.8 9.29 14.71C9.2 14.61 9.13 14.51 9.08 14.38C9.03 14.26 9 14.13 9 14C9 13.87 9.03 13.74 9.08 13.62C9.13 13.5 9.2 13.39 9.29 13.29C9.39 13.2 9.5 13.13 9.62 13.08C9.86 12.98 10.14 12.98 10.38 13.08C10.5 13.13 10.61 13.2 10.71 13.29C10.8 13.39 10.87 13.5 10.92 13.62C10.97 13.74 11 13.87 11 14C11 14.13 10.97 14.26 10.92 14.38Z"
        fill="#FFAD0D"
      />
    </svg>
  )
}

const ToastCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="cursor"
    >
      <path
        d="M17.9998 6C17.8123 5.81253 17.558 5.70721 17.2928 5.70721C17.0277 5.70721 16.7733 5.81253 16.5858 6L11.9998 10.586L7.41382 6C7.22629 5.81253 6.97198 5.70721 6.70682 5.70721C6.44165 5.70721 6.18735 5.81253 5.99982 6C5.81235 6.18753 5.70703 6.44184 5.70703 6.707C5.70703 6.97217 5.81235 7.22647 5.99982 7.414L10.5858 12L5.99982 16.586C5.81235 16.7735 5.70703 17.0278 5.70703 17.293C5.70703 17.5582 5.81235 17.8125 5.99982 18C6.18735 18.1875 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1875 7.41382 18L11.9998 13.414L16.5858 18C16.7733 18.1875 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1875 17.9998 18C18.1873 17.8125 18.2926 17.5582 18.2926 17.293C18.2926 17.0278 18.1873 16.7735 17.9998 16.586L13.4138 12L17.9998 7.414C18.1873 7.22647 18.2926 6.97217 18.2926 6.707C18.2926 6.44184 18.1873 6.18753 17.9998 6Z"
        fill="#9CA3AF"
      />
    </svg>
  )
}
const PauseCircleOutlineSharpIcon = props => {
  return <img className="" src="assets/images/pause.svg" alt="" width={20} />
}
const StopCircleOutlinedIcon = props => {
  return (
    <img className="" src="assets/images/stop-record.svg" alt="" width={20} />
  )
}

export {
  AddMoreBlueIcon,
  IdeaIcon,
  FavIcon,
  RecordingIcon,
  CartIcon,
  AddBlueIcon,
  AddIcon,
  DeleteIcon,
  DrumIcon,
  SuccessIcon,
  ErrorIcon,
  WarningIcon,
  ToastCloseIcon,
  PauseCircleOutlineSharpIcon,
  StopCircleOutlinedIcon
}
