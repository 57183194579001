import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'

import Sidebar from '../components/leftPane/Sidebar.js'
import MainPane from '../components/mainPage/MainPane.js'
import {
  AppBar,
  DrawerHeader2,
  drawerWidth,
  Main,
  rightDrawerWidth
} from '../utils/styled.js'
import { useDispatch, useSelector } from 'react-redux'
import EditToolbar from './../components/mainPage/EditToolbar'

import { useEditor } from '@tiptap/react'
import { Color } from '@tiptap/extension-color'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Paragraph from '@tiptap/extension-paragraph'
import TextStyle from '@tiptap/extension-text-style'
import Document from '@tiptap/extension-document'
import FontFamily from '@tiptap/extension-font-family'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import { FontSize } from '../utils/CustomExtensions.js'
import Footer from '../components/Footer/Footer.js'
import RightHeader from '../components/rightPage/RightHeader.js'
import IdeaPage from '../components/rightPage/Ideas/IdeaPage.js'
import { TabPanel } from '../utils/Tabs.js'
import ChordNotation from '../components/rightPage/ChordNotation/ChordNotation.js'
import Recording from '../components/rightPage/Recording/Recording.js'
import Cart from '../components/rightPage/Cart/Cart.js'
import { getMethod, putMethod } from '../helper/ApiMethods.js'
import { Api } from '../helper/ApiList.js'
import { useAlert } from 'react-alert'
import { allSongAction } from '../services/actions/song.action.js'
import { shiftUpdateSongElement } from '../utils/commonFunctions.js'
import { backTrackAction } from '../services/actions/mediaPlayerActions/backTrack.action.js'
import { loaderAction } from '../services/actions/loader.action.js'
import { getFavoriteTagsAction } from '../services/actions/cartTab/cartTabAction.js'
import AIAssistantFooter from '../components/Footer/AIAssistantFooter.js'
import { useMemo } from 'react'
import { LeftRightPaneFolderTypes } from '../utils/commonOptions.js'
import { constants } from '../services/constants/index.js'

export default function Home() {
  const { disabled } = useSelector(state => state.loader)

  const dispatch = useDispatch()

  const blockRef = React.useRef(null)

  const { leftToggle } = useSelector(state => state.leftToggle)
  const { assistantTab } = useSelector(state => state.assistantTab)
  const { rightToggle } = useSelector(state => state.rightToggle)
  const { singleSong, singleNotebook } = useSelector(state => state.singleSong)
  const { token } = useSelector(state => state.auth)
  const { type } = useSelector(state => state.songs)

  const [songDescription, setSongDescription] = React.useState('')
  const [value, setValue] = React.useState(0)
  const { songs, title, folderId } = useSelector(state => state.songs)

  const newIdeas = sessionStorage.getItem('new_ideas')

  const [isFullToolbar, setIsFullToolbar] = React.useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (newValue == 2) {
      setIsFullToolbar(true)
      return
    } else {
      setIsFullToolbar(false)
    }
  }

  const regex = /(\d+)\.\n/g

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Type here...'
        }),
        Document,
        Paragraph,
        Underline,
        TextStyle,
        Color,
        Text,
        FontFamily,
        FontSize,
        TextAlign.configure({
          types: ['heading', 'paragraph', 'span']
        }),
        Highlight.configure({ multicolor: true })
      ],
      content: singleNotebook?.notebookContent?.replace(regex, '$1. \n') || '',
      editable: !disabled,
      // enableInputRules: false
      onUpdate({ editor }) {
        setSongDescription(editor.getHTML())
      }
    },
    [singleNotebook, singleSong]
  )

  const handleUpdate = async () => {
    let body = {
      notebookContent: songDescription
    }

    if (!singleNotebook) return false

    const { error } = await putMethod(
      token,
      Api.updateNotebook + '/' + singleNotebook?._id,

      body
    )
    if (!error) {
      if (type === LeftRightPaneFolderTypes.NOTES) {
        const arr = await shiftUpdateSongElement(
          songs,
          songDescription,
          singleNotebook
        )
        dispatch(allSongAction(arr, title, '', LeftRightPaneFolderTypes.NOTES))
      }

      if (singleNotebook?.isNotebook) {
        let item = singleNotebook
        item['notebookContent'] = songDescription
        dispatch({
          type: constants.SINGLE_SONG,
          payload: { song: singleSong, singleNotebook: item }
        })
      }
    }
  }

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleUpdate()
      // Send Axios request here
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [songDescription])

  React.useEffect(() => {
    const fetchData = async () => {
      const { data } = await getMethod(token, Api.getBackTrack)
      dispatch(backTrackAction(data))
    }

    token && fetchData()
    // fetchFavoriteTags();
  }, [])

  const footerActiveCls = useMemo(() => {
    if (leftToggle && rightToggle) {
      return 'both-sidebar-active'
    }
    if (leftToggle) {
      return 'left-sidebar-active'
    }

    if (rightToggle) {
      return 'right-header-sidebar-active'
    }
    return 'inactive'
  }, [leftToggle, rightToggle])

  React.useEffect(() => {
    sessionStorage.setItem('new_ideas', '')
    if (type === LeftRightPaneFolderTypes.NOTES) {
      setIsFullToolbar(true)
    } else {
      setIsFullToolbar(false)
    }
  }, [type])

  React.useEffect(() => {
    if (type !== LeftRightPaneFolderTypes.AI_ASSISTANT) {
      window.history.pushState({}, '', `/`)
    }
  }, [type, newIdeas])

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        open={leftToggle}
        className={`toolbar-new ${footerActiveCls}`}
        // className={`toolbar-new ${footerActiveCls}`}
      >
        <Toolbar className="toolbar-wrapper">
          <EditToolbar
            editor={editor}
            isFullToolbar={isFullToolbar}
            blockRef={blockRef}
          />
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={leftToggle}
        className="left-bar"
        // className="main-wrapper left-bar"
      >
        <div className="aside">
          <Sidebar />
        </div>
      </Drawer>
      {/* Main section Start */}

      <Main
        open={leftToggle}
        className={`${
          rightToggle ? 'shiftRight' : 'resetRight'
        } main-wrapper ${footerActiveCls}`}
      >
        <MainPane
          editor={editor}
          setIsFullToolbar={setIsFullToolbar}
          ref={blockRef}
        />
        {/* !assistantTab && */}
        {singleSong && type !== LeftRightPaneFolderTypes.NOTES && <Footer />}

        {/* {type === LeftRightPaneFolderTypes.AI_ASSISTANT&& <AIAssistantFooter />} */}
      </Main>

      {/* Main section end */}

      <Drawer
        className={`rightDrawer ${footerActiveCls}`}
        sx={{
          width: rightDrawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: rightDrawerWidth
          }
        }}
        variant="persistent"
        anchor="right"
        open={rightToggle}
      >
        <DrawerHeader2 className="right-header">
          <RightHeader value={value} handleChange={handleChange} />
        </DrawerHeader2>

        <div className="rside">
          <TabPanel value={value} index={0}>
            <IdeaPage />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <ChordNotation />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <Recording />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <Cart />
          </TabPanel> */}
        </div>
      </Drawer>
    </Box>
  )
}
