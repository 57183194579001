/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { getMethod, postMethod } from '../helper/ApiMethods'
import { Api, socket_url } from '../helper/ApiList'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { loaderAction } from '../services/actions/loader.action'
import { useAlert } from 'react-alert'
import Radio from '@mui/material/Radio'

import { constants } from '../services/constants'
import { io } from 'socket.io-client'
import { useRef } from 'react'
import jwt_decode from 'jwt-decode'
import '../css/plan.css'

import { currencyFormat, months } from '../utils/commonFunctions'
import { toast } from '../components/Toast/Toast'
import MacPrice from '../components/mac-subscription/MacPrice'
const NewPlan = () => {
  const socket = useRef()

  const { token } = useSelector(state => state.auth)
  const params = new URLSearchParams(window.location.search)
  const session_id = params.get('session_id')
  const [active, setActive] = useState('monthly')
  const dispatch = useDispatch()
  const [isCancel, setIsCancel] = useState({
    isCancel: false,
    plan: 'free'
  })

  const [subList, setSubList] = useState({
    monthly: [],
    yearly: [],
    oneTimePlan: []
  })

  const [isMonthly, setIsMonthly] = useState(false)

  const [connected, setConnected] = useState(false)

  const [loader, setLoader] = useState({ id: null, name: '', loading: false })

  useEffect(() => {
    socket.current = io(socket_url, { transports: ['websocket'] })
    socket.current.on('connect', () => {
      setConnected(true)
      console.log('socket connected')
    })
  }, [])

  useMemo(() => {
    const getSubList = async () => {
      dispatch(loaderAction(true))
      try {
        const { data } = await getMethod(token, Api.get_subscriptions)
        // if (data?.prices?.length) {
        setSubList({
          monthly: data?.plan?.monthlyPlan,
          yearly: data?.plan?.yearlyPlan,
          oneTimePlan: data?.plan?.oneTimePlan
        })

        setLoader({
          id: data?.plan?.monthlyPlan[1].id,
          name: data?.plan?.monthlyPlan[1]?.product?.name,
          loading: false
        })
        setIsMonthly(false)
        // }
      } catch (error) {}
      dispatch(loaderAction(false))
    }
    token && getSubList()
  }, [])

  useEffect(() => {
    const retrieveSubscription = async () => {
      dispatch(loaderAction(true))
      var decoded = jwt_decode(token)

      try {
        const url = `${Api.retrieve_subscription}?session_id=${session_id}`
        const { data, error } = await getMethod(token, url)

        if (error) {
          toast.error(error)
          return
        }

        socket.current.emit('payment_success', {
          token: data?.accessToken,
          userId: decoded?._id
        })

        toast.success(data?.message)
        // console.log();
        setTimeout(() => {
          window.close()
        }, 50)
      } catch (error) {
        console.log(error, 'Error while retrieving payment')
      }
      dispatch(loaderAction(false))
    }
    session_id && connected && retrieveSubscription()
  }, [session_id, connected])

  const handleCheckout = async () => {
    if (loader?.loading) return
    try {
      setLoader({ ...loader, loading: true })
      const { data, error } = await postMethod(token, Api.stripe_checkout, {
        priceId: loader?.id,
        isOneTime: subList?.oneTimePlan.find(ele => ele?.id === loader?.id)
          ? true
          : false
      })

      if (error) {
        toast.error(error)
      }
      if (data) {
        window.location.href = data
      }
    } catch (error) {
      toast.error(error?.response?.data.message || error.message)
    }
    setLoader({ ...loader, id: loader?.id, loading: false })
  }

  useEffect(() => {
    const retrieveSubscription = async () => {
      dispatch(loaderAction(true))
      var decoded = jwt_decode(token)

      try {
        const url = `${Api.retrieve_subscription}?session_id=${session_id}`
        const { data, error } = await getMethod(token, url)

        if (error) {
          toast.error(error)
          return
        }

        socket.current.emit('payment_success', {
          token: data?.accessToken,
          userId: decoded?._id
        })

        toast.success(data?.message)
        setTimeout(() => {
          window.close()
        }, 50)
      } catch (error) {
        console.log(error, 'Error while retrieving payment')
      }
      dispatch(loaderAction(false))
    }
    session_id && connected && retrieveSubscription()
  }, [session_id, connected])

  useEffect(() => {
    if (token) {
      const userInfo = async () => {
        const { data } = await getMethod(token, Api.userInfo)
        setIsCancel({
          isCancel: data?.pro?.isCancel || false,
          plan: data?.planType
        })

        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: {
            token: data?.accessToken || token,
            email: data.email,
            plan: data.planType,
            user: data
          }
        })
      }

      userInfo()
    }
  }, [])

  const handleCancel = async () => {
    try {
      dispatch(loaderAction(true))

      const { data, rest } = await getMethod(token, Api.cancel)

      toast.success(rest?.message)
      setIsCancel({
        isCancel: true
      })
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
    }
    dispatch(loaderAction(false))
  }

  const handleTabActive = (type = 'monthly') => {
    let plan = subList?.[type]
    setLoader({
      id: plan && plan?.length > 1 ? plan[1]?.id : plan[0]?.id,
      loading: false,
      name:
        plan && plan?.length > 1
          ? plan[1]?.product?.name
          : plan[0]?.product?.name
    })
    setActive(type)
  }

  const isNativeMac = localStorage.getItem('isMac')

  return isNativeMac == 'true' ? (
    <MacPrice />
  ) : (
    <>
      <section className="plan-new py-4">
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="logo-header text-left">
                <img
                  src="assets/images/logo_new.svg"
                  className="img-fluid"
                  width="250"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="tab-div row">
                <div
                  onClick={() => handleTabActive('monthly')}
                  className={`col-4 cursor ${
                    active === 'monthly' && 'plan-active'
                  }`}
                >
                  <div className="monthly">Monthly</div>
                </div>
                <div
                  onClick={() => handleTabActive('yearly')}
                  className={`col-4 cursor ${
                    active === 'yearly' && 'plan-active'
                  }`}
                >
                  <div className="yearly">Yearly</div>
                </div>
                <div
                  onClick={() => handleTabActive('oneTimePlan')}
                  className={`col-4 cursor ${
                    active === 'oneTimePlan' && 'plan-active'
                  }`}
                >
                  <div className="yearly">One Time Buy</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 text-left">
              <div className="">
                <h5>Next level your songwriting today!</h5>
              </div>
            </div>
          </div>
          <div className="tick-option">
            <div className="row mt-2">
              <div className="col-6">
                <div className="tick-with-text">
                  <p>
                    <img
                      src="assets/images/tick-mark.svg"
                      className="mr-2"
                      width="22"
                      alt="tick"
                    />{' '}
                    Advanced AI Song Lyric Generator
                  </p>
                  <p>
                    <img
                      src="assets/images/tick-mark.svg"
                      className="mr-2"
                      width="22"
                      alt="tick"
                    />{' '}
                    Lyric and Notes editor
                  </p>

                  <p>
                    <img
                      src="assets/images/tick-mark.svg"
                      className="mr-2"
                      width="22"
                      alt="tick"
                    />{' '}
                    Mood Idea Generator
                  </p>
                  <p>
                    <img
                      src="assets/images/tick-mark.svg"
                      className="mr-2"
                      width="22"
                      alt="tick"
                    />{' '}
                    Rhymes, Dictionary, and Thesaurus
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="tick-with-text">
                  <p>
                    <img
                      src="assets/images/tick-mark.svg"
                      className="mr-2"
                      width="22"
                      alt="tick"
                    />{' '}
                    Audio recorder with unlimited recordings
                  </p>
                  <p>
                    <img
                      src="assets/images/tick-mark.svg"
                      className="mr-2"
                      width="22"
                      alt="tick"
                    />{' '}
                    Auto-Sync + Folder & Song management{' '}
                  </p>
                  <p>
                    <img
                      src="assets/images/tick-mark.svg"
                      className="mr-2"
                      width="22"
                      alt="tick"
                    />{' '}
                    Use on all your mobile & desktop devices
                  </p>
                </div>
              </div>
            </div>
          </div>
          {subList?.[active]?.map((item, index) => (
            <div
              className={`mt-2 Horizontal-plan-tab ${
                item?.id === loader?.id ? 'active' : 'inactive'
              } cursor"`}
              onClick={e => {
                setLoader({
                  id: item?.id,
                  loading: false,
                  name: item?.product?.name
                })
              }}
            >
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-1">
                  <div className="check-div">
                    <Radio
                      checked={item?.id === loader?.id}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 28
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-7">
                  <div className="price-sec-text">
                    <h5>{item?.product?.name || 'N/A'}</h5>
                    <p>
                      {item?.nickname || item?.product?.description || 'N/A'}
                    </p>
                    {!['monthly', 'oneTimePlan'].includes(active) && (
                      <p
                        className={`${
                          item?.id === loader?.id ? '' : 'text-dark'
                        } `}
                      >
                        {' '}
                        <span className="pe-2 depricate">
                          {currencyFormat(
                            item?.priceStatics?.withoutDisYearPrice
                          )}
                        </span>
                        That's only{' '}
                        {currencyFormat(item?.priceStatics?.yearMonth)} p/mo
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-4 text-end">
                  {['monthly', 'oneTimePlan'].includes(active) ? (
                    <div
                      className={`most-populor ${
                        ['oneTimePlan'].includes(active) || index !== 1
                          ? 'd-none'
                          : ''
                      }`}
                    >
                      <span className="dot-span">
                        <Radio checked size="small" />
                      </span>
                      <span className="popular">Most Popular</span>
                    </div>
                  ) : (
                    <>
                      <div className="most-populor">
                        <span className="dot-span">
                          <Radio checked size="small" />
                        </span>
                        <span className="popular">
                          Save {item?.priceStatics?.yearMonthDis}%
                        </span>
                      </div>
                    </>
                  )}
                  <div className="plan-price-right">
                    <h6 className="m-0">
                      {['monthly', 'oneTimePlan'].includes(active) ? (
                        <>
                          {currencyFormat(item?.unit_amount / 100)}{' '}
                          {months(item?.recurring)
                            ? '/' + months(item?.recurring)
                            : ''}
                        </>
                      ) : (
                        <>{currencyFormat(item?.unit_amount / 100)}/Year</>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="text-center">
                <button
                  className="Continue-Pro"
                  onClick={() => handleCheckout()}
                  disabled={!loader?.id}
                >
                  {loader?.loading
                    ? 'Loading...'
                    : `Continue with ${loader?.name}`}
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="plan-footer-text text-center">
                <span>Risk free, cancel anytime. </span>
                <span> Secure payment. </span>
              </div>

              <div className="text-center term-policy mt-2">
                {isCancel?.isCancel === null ||
                  (!isCancel?.isCancel && (
                    <>
                      <a href="#" onClick={() => handleCancel()}>
                        {' '}
                        Cancel Subscription{' '}
                      </a>
                      <span className="m-3"> | </span>
                    </>
                  ))}
                <a
                  href="https://songwriterspad.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Privacy Policy{' '}
                </a>
                <span className="m-3"> | </span>
                <a
                  href="http://www.songwriterspad.com/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Terms of Use
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewPlan
