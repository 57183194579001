const musicSymbolsCodes = [
  <>&#9834;</>,
  <>&#9835;</>,
  <>&#9833;</>,
  <>&#9836;</>,
  <>&#9837;</>,
  <>&#9838;</>,
  <>&#9839;</>
]

const musicSymbolsCodesWheel1 = [
  'C',
  <>C&#9839;</>,
  <>D&#9837;</>,
  'D',
  <>D&#9839;</>,
  <>E&#9837;</>,
  'E',
  'F',
  <>F&#9839;</>,
  <>G&#9837;</>,
  'G',
  <>G&#9839;</>,
  <>A&#9837;</>,
  'A',
  <>A&#9839;</>,
  <>B&#9837;</>,
  <>&#9837;</>,
  <>C#9837;</>
]
const musicSymbolsCodesWheel2 = [
  'maj',
  'm',
  'sus2',
  'sus4',
  '5',
  '7',
  '7sus4',
  'm7',
  'maj7',
  'dim',
  'aug',
  '9/13'
]

const musicSymbolsDragCodes = [
  '/C',
  <>/C&#9839;</>,
  // '/C#',
  <>/D&#9837;</>,
  '/D',
  <>/D&#9839;</>,
  <>/E&#9837;</>,
  '/E',
  'F',
  <>/F&#9839;</>,
  <>/G&#9837;</>,
  '/G',
  <>/G&#9839;</>,
  <>/A&#9837;</>,
  '/A',
  <>/A&#9839;</>,
  <>/B&#9837;</>,
  <>/&#9837;</>,
  <>/C&#9837;</>
]

export {
  musicSymbolsCodes,
  musicSymbolsCodesWheel2,
  musicSymbolsCodesWheel1,
  musicSymbolsDragCodes
}
