import { loaderAction } from './loader.action';

import {
  removeAuthentication,
  removeSession,
  setEmail,
  setSession
} from '../../helper/cookies';

const authAction = payload => async dispatch => {
  dispatch(loaderAction(true));

  let token = payload.accessToken;
  let email = payload.email;
  setSession(token, () => {
    setEmail(email, () => {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
  });
  dispatch(loaderAction(false));
};



// here we are refreshing token after take subscription 
const refreshToken = payload => async dispatch => {
  dispatch(loaderAction(true));

  let token = payload.accessToken;
  let email = payload.email;
  setSession(token, () => {
    setEmail(email, () => { });
  });
  dispatch(loaderAction(false));

};

export const logoutAction = () => async dispatch => {
  dispatch(loaderAction(true));
  removeAuthentication(() => {
    removeSession(() => {
      setTimeout(() => {
        window.location.reload();
      }, 100);
      dispatch(loaderAction(false));
    });
  });
};

export { authAction, refreshToken };
