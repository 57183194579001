import { detectSystemOs } from './commonFunctions'

const lyricStyles = [
  'Pop',
  'Hip-hop / Rap',
  'Country',
  'R&B',
  'Rock',
  'Latin'
  // 'Electronic / EDM',
  // 'Jazz',
  // 'Blues',
  // 'Gospel',
  // 'Soul',
  // 'Funk',
  // 'Reggae',
  // 'Classical',
  // 'Punk',
  // 'Metal',
  // 'Alternative',
  // 'Indie',
  // 'Folk',
  // 'K-pop',
  // 'J-pop',
  // 'Bollywood',
  // 'Hip-life',
  // 'Bongo Flava',
  // 'Salsa',
  // 'Flamenco',
  // 'C-pop',
  // 'World'
]

const aiAssistant = {
  theme: '',
  genre: 'Pop',
  style: '',
  verses: 1,
  pre_chorus: false,
  chorus: true,
  bridge: false,
  outro: false,
  intro: false,
  lang: '',
  typeOfIdeas: '',
  isTitle: false
}

const languageOptions = [
  {
    flag: 'us',
    lang: 'English'
  },
  {
    flag: 'es',
    lang: 'Spanish'
  },
  {
    flag: 'de',
    lang: 'German'
  },
  {
    flag: 'fr',
    lang: 'French'
  }
]

const LeftRightPaneFolderTypes = {
  AI_ASSISTANT: 'AI_ASSISTANT',
  AI_IDEAS: 'AI_IDEAS',
  NOTES: 'NOTES',
  FOLDERS: 'FOLDERS'
}

let aiTypes = ['AI_ASSISTANT', 'AI_IDEAS']

const hideTabs = ['AI_ASSISTANT']
const windowFeatures =
  window?.electron && detectSystemOs() === 'Mac'
    ? 'left=400,top=200,width=650,height=600'
    : 'left=300,top=100,width=780,height=650'

const limitAlert = {
  message:
    'You may not have enough word tokens available to complete this task.',
  below75: 'Sorry, you do not have enough word tokens available',
  250: 'Less than 250 words',
  75: 'Less than 75 words'
}

const languagePopMsg = {
  heading: 'Language',
  message: 'More languages coming soon.'
}

const filterOptions = [
  {
    order: 'newest',
    title: 'Date - Newest'
  },
  {
    order: 'oldest',
    title: 'Date - Oldest'
  },
  {
    order: 'aToZ',
    title: 'Title - A to Z'
  },
  {
    order: 'zToA',
    title: 'Title - Z to A'
  }
]

export {
  languageOptions,
  aiAssistant,
  lyricStyles,
  LeftRightPaneFolderTypes,
  hideTabs,
  limitAlert,
  languagePopMsg,
  filterOptions,
  aiTypes,
  windowFeatures
}
