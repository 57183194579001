import { aIAssistantConstants, constants } from '../constants'

const initialState = {
  leftToggle: true
}
const rightState = {
  rightToggle: true
}
const folderState = {
  folderToggle: false,
  singleFolderData: null
}

const songState = {
  songToggle: false,
  singleSongData: null
}

const notebookState = {
  notebookToggle: false,
  singleNotebookData: null
}

const songBlockState = {
  songBlockToggle: false,
  type: null
}

const colorState = {
  colorToggle: false
}

const backColorState = {
  backColorToggle: false
}

const selectBtnState = {
  selectBtnToggle: false
}
const assistantTabState = {
  assistantTab: false,
  ai_generated: null,
  deleteAction: false
}

const assistantTabToggleReducer = (state = assistantTabState, action) => {
  switch (action.type) {
    case aIAssistantConstants.AI_ASSISTANT_TAB_ACTIVE:
      return {
        ...state,
        assistantTab: action.payload.boolean,
        ai_generated: action.payload.ai_generated
      }

    case aIAssistantConstants.AI_ASSISTANT_DELETE:
      return {
        ...state,
        deleteAction: action.payload.deleteAction,
        ai_generated: action.payload.ai_generated
      }

    default:
      return state
  }
}
const leftSidebarToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LEFT_SIDEBAR_TOGGLE:
      return {
        leftToggle: action.payload
      }

    default:
      return state
  }
}

const rightSidebarToggleReducer = (state = rightState, action) => {
  switch (action.type) {
    case constants.RIGHT_SIDEBAR_TOGGLE:
      return {
        rightToggle: action.payload
      }

    default:
      return state
  }
}

const addFolderToggleReducer = (state = folderState, action) => {
  switch (action.type) {
    case constants.FOLDER_TOGGLE:
      return {
        folderToggle: action.payload.toggle,
        singleFolderData: action.payload.data
      }

    default:
      return state
  }
}

const addSongToggleReducer = (state = songState, action) => {
  switch (action.type) {
    case constants.SONG_TOGGLE:
      return {
        songToggle: action.payload.toggle,
        singleSongData: action.payload.data
      }

    default:
      return state
  }
}

const addNotebookToggleReducer = (state = notebookState, action) => {
  switch (action.type) {
    case constants.NOTEBOOK_TOGGLE:
      return {
        notebookToggle: action.payload.toggle,
        singleNotebookData: action.payload.data
      }

    default:
      return state
  }
}

const addSongBlockToggleReducer = (state = songBlockState, action) => {
  switch (action.type) {
    case constants.SONG_BLOCK_TOGGLE:
      return {
        songBlockToggle: action.payload.toggle,
        type: action.payload.type
      }

    default:
      return state
  }
}

const colorToggleToggleReducer = (state = colorState, action) => {
  switch (action.type) {
    case constants.COLOR_TOGGLE:
      return {
        colorToggle: action.payload.toggle
      }
    default:
      return state
  }
}

const backgroundColorToggleReducer = (state = backColorState, action) => {
  switch (action.type) {
    case constants.BACKGROUND_COLOR_TOGGLE:
      return {
        backColorToggle: action.payload.toggle
      }

    default:
      return state
  }
}

const selectButtonToggleHideReducer = (state = selectBtnState, action) => {
  switch (action.type) {
    case constants.SELECT_BTN_TOGGLE:
      return {
        selectBtnToggle: action.payload.toggle
      }

    default:
      return state
  }
}

export {
  leftSidebarToggleReducer,
  rightSidebarToggleReducer,
  addFolderToggleReducer,
  addSongToggleReducer,
  addSongBlockToggleReducer,
  colorToggleToggleReducer,
  backgroundColorToggleReducer,
  selectButtonToggleHideReducer,
  assistantTabToggleReducer,
  addNotebookToggleReducer
}
