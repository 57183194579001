import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useDispatch } from 'react-redux'
import { playerConstants } from '../../services/constants'
import Tooltip from '@mui/material/Tooltip'

export default function VolumeBar({ value, onChange, min, max, className }) {
  const dispatch = useDispatch()
  const handleVolume = data => {
    dispatch({
      type: playerConstants.PLAYER_VOLUME,
      payload: { playerVolume: data }
    })
  }
  return (
    <Box>
      <Stack
        spacing={1}
        direction="row"
        sx={{ mb: 1 }}
        alignItems="center"
        className="volume-bar"
      >
        {/* <VolumeDown onClick={() => setVolume(0)} /> */}
        <Tooltip title="Mute" arrow>
          <img
            onClick={() => handleVolume(0)}
            className="volume-icon-left"
            src="assets/images/volume-down-new.svg"
            alt=""
            width={25}
          />
        </Tooltip>
        <input
          type="range"
          step="any"
          value={value}
          min={min}
          max={max}
          className={`volume-bar-input ${className && className}`}
          onChange={onChange}
        />
        <Tooltip title="Volume Full" arrow>
          <img
            onClick={() => handleVolume(1)}
            className="volume-icon-right"
            src="assets/images/volume-up-new.svg"
            alt=""
            width={23}
          />
        </Tooltip>
        {/* <VolumeUp onClick={() => setVolume(1)} /> */}
      </Stack>
    </Box>
  )
}
