import React from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import SongModal from '../../Modal/SongModal'
import { useDispatch, useSelector } from 'react-redux'
import { addSongBlockToggleAction } from '../../../services/actions/toggle.action'
import DragSongBlock from './DragSongBlock'

const SongBlocks = forwardRef((props, ref) => {
  const blockRef = useRef(null)

  const { disabled } = useSelector(state => state.loader)

  const { singleSong } = useSelector(state => state.singleSong)
  const dispatch = useDispatch()

  // this function is used to send the ref from child to parent
  useImperativeHandle(ref, () => ({
    childFunction1() {
      let data = blockRef.current.childFunction1()
      return data
    }
  }))

  return (
    <>
      <SongModal />
      <div className="row mb-2 mt-1">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <div className="song-list-name">
            <h5>{singleSong?.songHeading || ''}</h5>
          </div>
          <div className={`${disabled ? 'disable' : ''} add-block`}>
            <button
              className="add-block-btn-new"
              onClick={() => {
                dispatch(addSongBlockToggleAction())
              }}
            >
              <img src="assets/images/add-song-icon-new.svg" alt="" />
              Add Song Block
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="song_block_content">
          <DragSongBlock ref={blockRef} />
        </div>
      </div>
    </>
  )
})

export default SongBlocks
