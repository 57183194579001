import 'flag-icons/css/flag-icons.min.css'

import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import Home from './pages/Home'
import Login from './pages/Login'
import './App.css'
import './profile.css'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './Loader/Loader'
import SignUp from './pages/SignUp'
import { getAuthentication, getEmail, getSession } from './helper/cookies'
import { constants } from './services/constants'
import PrivateRoute from './HOC/PrivateRoute/PrivateRoute'
import RedirectRoute from './HOC/PrivateRoute/RedirectRoute'
import { Api, socket_url } from './helper/ApiList'
import Price from './pages/Price'
import jwt_decode from 'jwt-decode'

import io from 'socket.io-client'
import { logoutAction, refreshToken } from './services/actions/auth.action'
import Profile from './pages/Profile'
import Success from './pages/Success'
import SetPassword from './pages/SetPassword'
import ForgetPassword from './pages/ForgetPassword'
import { getMethod } from './helper/ApiMethods'
import { CountDown } from './Loader/CountDown'
import Failed from './Failed'
import PreviewPage from './pages/PreviewPage'
import Referral from './pages/Referral'
import ReferralBalance from './pages/ReferralBalance'
import { NoInternetConnection } from './Loader/ButtonLoader'
import AudioMixer from './pages/AudioMixer'
import { detectSystemOs } from './utils/commonFunctions'

function App() {
  const params = new URLSearchParams(window.location.search)
  const logout = params.get('logout')
  const { token, user } = useSelector(state => state.auth)
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const socket = useRef()
  const { loader, countDown } = useSelector(state => state.loader)
  const [decodedToken, setDecodedToken] = useState(null)

  const [wait, setWait] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const isLoggedIn = () => {
      const token = getAuthentication()
      if (token) {
        let email = getEmail()
        const tokenDecode = jwt_decode(token)
        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: {
            token: token,
            email: email,
            plan: tokenDecode?.planType || 'free',
            user: user
          }
        })

        setDecodedToken(tokenDecode)
      } else {
        // getSession
        var sessionToken = getSession()
        if (sessionToken) {
          let email = getEmail()
          const tokenDecode = jwt_decode(sessionToken)
          dispatch({
            type: constants.LOGIN_SUCCESS,
            payload: {
              token: sessionToken,
              email: email,
              plan: tokenDecode?.planType || 'free',
              user: user
            }
          })
          setDecodedToken(tokenDecode)
        }
      }
      setWait(false)
    }
    isLoggedIn()
  }, [dispatch])

  useEffect(() => {
    socket.current = io(socket_url, { transports: ['websocket'] })
    socket.current.on('connect', () => {
      console.log('socket connected')
    })

    socket.current.on('get_payment_success', data => {
      const decoded = jwt_decode(data?.token)

      if (decodedToken?._id === data?.userId) {
        const res = {
          accessToken: data?.token,
          email: getEmail(),
          plan: decoded?._doc?.planType || decoded?.planType
        }
        dispatch(refreshToken(res))
        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: {
            token: data?.token,
            email: res?.email,
            plan: decoded?._doc?.planType || decoded?.planType,
            user: decoded?._doc || decoded
          }
        })

        setDecodedToken(null)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken])

  useEffect(() => {
    if (token) {
      const userInfo = async () => {
        const { data } = await getMethod(token, Api.userInfo)

        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: {
            token: data?.accessToken || token,
            email: data?.email,
            plan: data?.planType,
            user: data
          }
        })
      }

      userInfo()
    }
  }, [])

  useEffect(() => {
    if (logout) {
      dispatch(logoutAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout])

  useEffect(() => {
    if (window?.electron) {
      localStorage.setItem('isMac', detectSystemOs() === 'Mac' ? true : false)
      const ipcRenderer = window?.electron?.ipcRenderer
      ipcRenderer && ipcRenderer?.send('fsRead', 'Lorem Ipsum')
    }
  }, [])
  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  useEffect(() => {
    const handleMessage = event => {
      if (event.origin !== window.location.origin) {
        // Ignore messages from unexpected origins
        return
      }

      if (event.data === 'call-parent-function') {
        if (window?.electron) {
          const ipcRenderer = window.electron.ipcRenderer
          ipcRenderer.send(
            'openBrowser',
            'https://apps.apple.com/in/app/songwriter-pad-lyric-generator/id6462515092'
          )
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <>
      {/* {isOnline ? ( */}
      <div className="main">
        {loader && <Loader />}
        {countDown && <CountDown />}
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{ duration: 2000, className: 'toast_custom' }}
        />

        {!wait && (
          <BrowserRouter>
            <Switch>
              <Route exact path="/success" component={Success} />
              <Route exact path="/failed" component={Failed} />
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute exact path="/price" component={Price} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/preview" component={PreviewPage} />
              <RedirectRoute exact path="/login" component={Login} />
              <PrivateRoute exact path="/referral" component={Referral} />
              <PrivateRoute
                exact
                path="/referral-balance"
                component={ReferralBalance}
              />
              {/* <RedirectRoute exact path="/success" component={Success} /> */}
              <RedirectRoute
                exact
                path="/forget_password"
                component={ForgetPassword}
              />
              <RedirectRoute exact path="/signup" component={SignUp} />
              <RedirectRoute
                exact
                path="/set_password"
                component={SetPassword}
              />
              <RedirectRoute exact path="/audio-mixer" component={AudioMixer} />
            </Switch>
          </BrowserRouter>
        )}
      </div>
      {/* ) : (
        <NoInternetConnection />
      )} */}
    </>
  )
}

export default App
